
import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource, MatPaginator} from '@angular/material';
import Swal from 'sweetalert2';
import { ConfirmedAssistantService } from './confirmed-assistant.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-confirmed-assistant',
  templateUrl: './confirmed-assistant.component.html',
  styleUrls: ['./confirmed-assistant.component.scss']
})
export class ConfirmedAssistantComponent implements OnInit {

  @ViewChild('paginator') paginator: MatPaginator;
  isCollapsed = true;
  isLoading = false;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns = ['name', 'mail', 'society', 'church', 'sector', 'action'];

  constructor(private confirmedAssistantService: ConfirmedAssistantService) { }

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("profile-page");
    this.loadList();
  }

  loadList(){
    this.isLoading = true;
    this.confirmedAssistantService.getDataUsers().then(resp => {
      this.dataSource = new MatTableDataSource(resp.data);
      setTimeout(() => {
        
        this.dataSource.paginator = this.paginator;
      });
      
      //console.log(this.dataSource);
      this.isLoading = false;
    }).catch(err => {
      this.isLoading = false;
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: 'No se pudo cargar la información, intente más tarde',
      });
    });
  }


  download(){
    let dataExcel = [];
    this.dataSource.filteredData.map(item => {
            
      let data = {
        Nombre: item.nmbrAsst,
        Correo: item.crroAsst,
        Sociedad: item.sociedad,
        Sector: item.sector,
        Iglesia: item.iglesia
      };
      dataExcel.push(data);
  });
    const fileName = `Reporte_Asistentes`;
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataExcel);
    const workbook: XLSX.WorkBook = { Sheets: { 'Asistentes': worksheet }, SheetNames: ['Asistentes'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataFile: Blob = new Blob([excelBuffer], {
            type: EXCEL_TYPE
    });
      
    FileSaver.saveAs(dataFile, fileName+ new Date().getFullYear()+ (new Date().getMonth()+1)+ (new Date().getDate())  + EXCEL_EXTENSION);
  }

  showData(row){
    this.isLoading = true;
    this.confirmedAssistantService.getQR({key: row.AsRg_Key}).then(resp => {
      this.isLoading = false;
      Swal.fire({
        type: 'info',
        title: 'Código QR',
        html: `<img src="${resp}" />`
      });
    }).catch(err => {
      this.isLoading = false;
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: err,
      });
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
