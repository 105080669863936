import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {RegisterService} from './register.service';
import * as moment from 'moment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  isLoading=false;
  isCollapsed = true;
  formGroup: FormGroup;
  focus;
  focus1;
  focus2;
  focus3;
  focus4;
  focus5;
  focus6;
  focus7;
  date = new Date();
  
  constructor(private fb: FormBuilder, private registerService: RegisterService) { }

  ngOnInit() {
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("index-page");
    this.createForms();
    /*navigator.mediaDevices && navigator.mediaDevices.getUserMedia({audio:true, video: false})
    .then(res=> {
      this.sound.loop = true;
      this.sound.play();
    })
    .catch(err => console.log("err: ", err));*/
    
  }

  createForms(): void {
    this.formGroup = this.fb.group({
        name: ['', [Validators.required, Validators.minLength(4)]],
        email: ['', [Validators.required, Validators.email]],
        date: ['', []],
        society: ['Secundarios', Validators.required],
        church: ['', [Validators.required, Validators.minLength(2)]],
        sector: ['1', Validators.required],
        workshop: ['0'],
        phone: ['', [Validators.pattern('^[0-9]*$'), Validators.minLength(10)]],
    });
    //this.sound.play();
  }

  sendData(){

    if(!this.formGroup.valid){
      Swal.fire({
        type: 'warning',
        title: 'Atención.',
        text: 'Favor de llenar todos los campos de manera correcta.'
      });
      return;
    }
    let date = moment(this.formGroup.controls['date'].value).format('DD-MM-YYYY');
    this.isLoading = true;
    let data = {
      nombre: (this.formGroup.controls['name'].value).toUpperCase(),
      correo: this.formGroup.controls['email'].value,
      grupo: this.formGroup.controls['society'].value,
      sector: +this.formGroup.controls['sector'].value,
      iglesia: this.formGroup.controls['church'].value,
      fchaNcmn: moment().format('DD-MM-YYYY'),
      taller: '0',
      telefono: '0',
    }

    this.registerService.registerDataUser(data).then(resp => {
      this.isLoading = false;
      Swal.fire({
        type: 'success',
        title: 'Registro exitoso',
        text: 'En breve recibirás un correo con el código QR'
      });
      this.formGroup.reset();
    }).catch(err => {
      this.isLoading = false;
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: err,
      });
    });

  }

  dateOnly(event): boolean {
    return false;
  }

}
