import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BarcodeFormat } from '@zxing/library';
import { CheckRegisterService } from '../check-register/check-register.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-check-food',
  templateUrl: './check-food.component.html',
  styleUrls: ['./check-food.component.scss']
})

export class CheckFoodComponent implements OnInit {

  @ViewChild('myModal2') modalQR: ModalDirective;
  @ViewChild('myModal1') modalData: ModalDirective;
  @ViewChild('myModal3') modalAccess: ModalDirective;

  isCollapsed = true;
  isLoading = false;
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  hasDevices: boolean;
  hasPermission: boolean;

  qrResultString: string;
  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;

  viewCam = true;
  name = '';
  church = '';
  sector = '';
  keyAsst = '';
  cost = 0;
  originalCost = 0;
  isMan = new FormControl;
  isWoman = new FormControl;
  localCheck = false;
  isCheck = new FormControl;
  idCmda = 0;
  tipo = 0;
  

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  constructor(private checkRegisterService: CheckRegisterService) { }

  ngOnInit(): void {
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("index-page");

  }

  scrollToDownload(element: any) {
    element.scrollIntoView({ behavior: "smooth" });
  }

  clearResult(): void {
    this.qrResultString = null;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(resultString: string) {

    this.isLoading = true;
    this.qrResultString = resultString;
    this.checkRegisterService.getDataTokenFood({tkn: this.qrResultString}).then(resp => {
      //console.log(resp);
      if(resp.data.length === 0){
        this.keyAsst = '';
        this.isLoading = false;
        Swal.fire({
          type: 'warning',
          title: 'Atención',
          text: 'No se encontró información con el QR ingresado, favor de pasar a registro',
        });
      }else{
        if(resp.rgst.length > 0){
          this.name = resp.data[0].AsRg_Nombre;
          this.modalQR.hide();
          this.modalAccess.show();
          this.isLoading = false;
          return;
        }
        this.name = resp.data[0].AsRg_Nombre;
        this.church = resp.data[0].AsRg_Iglesia;
        this.sector = resp.data[0].AsRg_Sector;
        this.keyAsst = resp.data[0].AsRg_Key;
        this.cost = resp.data[0].descCmda;
        this.idCmda = resp.data[0].idComida;
        this.originalCost = resp.data[0].Monto;
        this.tipo = resp.data[0].AsRg_TipoDscn;
        this.viewCam = false;
        this.modalQR.hide();
        this.isLoading = false;
        this.modalData.show();
      }
      
    }).catch(err => {
      this.viewCam = false;
      this.isLoading = false;
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: err,
      });
    });
  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.currentDevice = device || null;
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  showCam(): void {
    this.viewCam = true;
    this.modalQR.show();
    //this.modalData.show();
  }

  checkAccess(){
   
    this.isLoading = true;
    this.modalData.hide();
    let data = {
      idCmda: this.idCmda,
      nombre: this.name,
      iglesia: this.church,
      sector: this.sector,
      tipo: this.tipo,
      key: this.keyAsst
    }
    console.log(data);
    this.checkRegisterService.setRegstFood(data).then(resp => {
      this.modalData.hide();
      Swal.fire({
        type: 'success',
        title: 'Acceso Registrado',
      });
      this.isLoading = false;
    }).catch(err => {
      this.viewCam = false;
      this.isLoading = false;
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: err,
      });
    });
    
  }

  localChurch(event){
    //console.log(this.localCheck);
    const check = (document.getElementById("localCheck") as HTMLInputElement);
    this.cost = this.originalCost;
    if(check.checked){
      this.cost = 120;
    }
  }

}

