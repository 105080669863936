import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class CheckRegisterService {

    private url = `${environment.api.apiCfj}`;
    private token;

    constructor(private httpClient: HttpClient) {
    }

    private extractData(res: Response) {
        let body = res;
        return body || {};
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.error.message.message || error.error.message);
    }


    getDataToken(data): Promise<any> {
        this.token = localStorage.getItem('Authorization');
        return this.httpClient
            .get(this.url + '/registro/getDataTkn', {
                params: data
            }).toPromise()
            .then(this.extractData)
            .catch(this.handleError);
    }

    setAccess(body) {
        return this.httpClient
            .post(this.url + '/registro/setAccess', body, {
                headers: new HttpHeaders({})
            })
            .toPromise()
            .then(this.extractData)
            .catch(this.handleError);
    }

    getDataTokenFood(data): Promise<any> {
        this.token = localStorage.getItem('Authorization');
        return this.httpClient
            .get(this.url + '/registro/getDataTknFood', {
                params: data
            }).toPromise()
            .then(this.extractData)
            .catch(this.handleError);
    }

    setRegstFood(body) {
        return this.httpClient
            .post(this.url + '/registro/setRgstCmd', body, {
                headers: new HttpHeaders({})
            })
            .toPromise()
            .then(this.extractData)
            .catch(this.handleError);
    }


}