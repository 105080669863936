import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { IndexComponent } from "./pages/index/index.component";
import { ProfilepageComponent } from "./pages/examples/profilepage/profilepage.component";
import { RegisterpageComponent } from "./pages/examples/registerpage/registerpage.component";
import { LandingpageComponent } from "./pages/examples/landingpage/landingpage.component";
import { CheckRegisterComponent } from './pages/check-register/check-register.component';
import { RegisteredListComponent } from './pages/registered-list/registered-list.component';
import { ConfirmedAssistantComponent } from './pages/confirmed-assistant/confirmed-assistant.component';
import { RegisterComponent } from "./pages/register/register.component";
import {LocalRegistryComponent} from "./pages/local-registry/local-registry.component";
import {CheckFoodComponent} from './pages/check-food/check-food.component';

const routes: Routes = [
  { path: "", redirectTo: "register", pathMatch: "full" },
  { path: "home", component: IndexComponent },
  //{ path: "profile", component: ProfilepageComponent },
  //{ path: "registerTemplate", component: RegisterpageComponent },
  //{ path: "landing", component: LandingpageComponent },
  { path: "check", component: CheckRegisterComponent},
  { path: "list", component: RegisteredListComponent},
  { path: "confirm", component: ConfirmedAssistantComponent},
  { path: "register", component: RegisterComponent},
  { path: "local-registry", component: LocalRegistryComponent},
  { path: "check-food", component: CheckFoodComponent},
  
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: []
})
export class AppRoutingModule {}
