import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs/add/operator/toPromise';

@Injectable({
  providedIn: 'root'
})
export class RegisterListService {

  private url = `${environment.api.apiCfj}`;
    private token;

    constructor(private httpClient: HttpClient) {
    }

    private extractData(res: Response) {
        let body = res;
        return body || {};
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.error.message.message || error.error.message);
    }


    getDataUsers(): Promise<any> {
        return this.httpClient
            .get(this.url + '/registro/getDataAssistant', {
            }).toPromise()
            .then(this.extractData)
            .catch(this.handleError);
    }

    setAccess(body) {
      return this.httpClient
          .post(this.url + '/registro/setAccess', body, {
              headers: new HttpHeaders({})
          })
          .toPromise()
          .then(this.extractData)
          .catch(this.handleError);
    }

    registerDataUser(body) {
      return this.httpClient
          .post(this.url + '/registro/registerAssistant', body, {
              headers: new HttpHeaders({})
          })
          .toPromise()
          .then(this.extractData)
          .catch(this.handleError);
  }
  

}
