import { Component, OnInit, OnDestroy } from "@angular/core";
import {MatTableDataSource} from '@angular/material';

@Component({
  selector: "app-profilepage",
  templateUrl: "profilepage.component.html"
})
export class ProfilepageComponent implements OnInit, OnDestroy {
  isCollapsed = true;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns = ['item', 'stock', 'max', 'action'];

  constructor() {}

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("profile-page");
  }
  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("profile-page");
  }
}
