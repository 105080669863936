import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {RegisterService} from '../register/register.service';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-local-registry',
  templateUrl: './local-registry.component.html',
  styleUrls: ['./local-registry.component.scss']
})
export class LocalRegistryComponent implements OnInit {

  isLoading=false;
  isCollapsed = true;
  formGroup: FormGroup;
  focus;
  focus1;
  focus2;
  focus3;
  focus4;
  focus5;
  focus6;
  focus7;
  focus10;
  date = new Date();
  costList = [];
  isMan = new FormControl;
  isWoman = new FormControl;

  constructor(private fb: FormBuilder, private registerService: RegisterService) { }

  ngOnInit() {
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("index-page");
    this.createForms();
    this.loadCost();
  }

  loadCost(){
    this.registerService.getListCost().then(resp => {
      this.costList = resp.data;
    }).catch(err => {
      this.isLoading = false;
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: err,
      });
    });
  }

  createForms(): void {
    this.formGroup = this.fb.group({
        name: ['', [Validators.required]],
        email: ['', []],
        date: ['', []],
        society: ['Jóvenes', Validators.required],
        church: ['', Validators.required],
        sector: ['1', Validators.required],
        workshop: ['0'],
        phone: ['', [Validators.pattern('^[0-9]*$'), Validators.minLength(10)]],
        cost: ['0', Validators.required],
    });
  }

  sendData(){

    if(!this.formGroup.valid){
      Swal.fire({
        type: 'warning',
        title: 'Atención.',
        text: 'Favor de llenar todos los campos de manera correcta.'
      });
      return;
    }

    //const radio1 = (document.getElementById("gender1") as HTMLInputElement);
    //const radio2 = (document.getElementById("gender2") as HTMLInputElement);
    let gender = 0;
    /*if(!radio1.checked && !radio2.checked){
      Swal.fire({
        type: 'warning',
        title: 'Atención.',
        text: 'Favor de elegir un género.'
      });
      return;
    }*/

    if(+this.formGroup.controls['cost'].value === 0){
      Swal.fire({
        type: 'warning',
        title: 'Atención.',
        text: 'Favor de elegir un monto.'
      });
      return;
    }

    let montoIndex = this.costList.findIndex(item => item.Csts_IdCosto === +this.formGroup.controls['cost'].value);


    //if(radio1.checked) gender = 1;
    //if(radio2.checked) gender = 2;
    

    let date = moment(this.formGroup.controls['date'].value).format('DD-MM-YYYY');
    this.isLoading = true;
    let data = {
      nombre: (this.formGroup.controls['name'].value).toUpperCase(),
      correo: this.formGroup.controls['email'].value,
      grupo: this.formGroup.controls['society'].value,
      sector: +this.formGroup.controls['sector'].value,
      iglesia: this.formGroup.controls['church'].value,
      //fchaNcmn: date,
      //taller: +this.formGroup.controls['workshop'].value,
      telefono: (this.formGroup.controls['phone'].value).toString(),
      monto: this.costList[montoIndex].Csts_Monto,
      idMonto: this.costList[montoIndex].Csts_IdCosto,

      //genero: gender
    }
    this.registerService.registerLocalDataUser(data).then(resp => {
      this.isLoading = false;
      Swal.fire({
        type: 'success',
        title: 'Registro exitoso',
        html: `<img src="${resp.data}" />`
      });
      this.formGroup.reset();
    }).catch(err => {
      this.isLoading = false;
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: err,
      });
    });

  }

  dateOnly(event): boolean {
    return false;
  }



}
