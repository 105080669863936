import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource, MatPaginator} from '@angular/material';
import {RegisterListService} from './register-list.service';
import Swal from 'sweetalert2';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { FormControl } from '@angular/forms';
import {CheckRegisterService} from '../check-register/check-register.service';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-registered-list',
  templateUrl: './registered-list.component.html',
  styleUrls: ['./registered-list.component.scss']
})
export class RegisteredListComponent implements OnInit {

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  isCollapsed = true;
  isLoading = true;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns = ['name', 'mail', 'society', 'church', 'sector', 'action'];
  viewCam = true;
  name = '';
  church = '';
  sector = '';
  keyAsst = '';
  cost = 0;
  originalCost = 0;
  isMan = new FormControl;
  isWoman = new FormControl;
  localCheck = false;
  isCheck = new FormControl;
  @ViewChild('myModal1') modalData: ModalDirective;
  @ViewChild('myModal2') modalregister: ModalDirective;
  formGroup: FormGroup;
  idUser = 0;
  tipoDsc = 0;

  constructor(private registerListService: RegisterListService,
    private fb: FormBuilder,
    private checkRegisterService: CheckRegisterService) {}

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("profile-page");
    this.createForms();
    this.loadAssistant();
  }
  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("profile-page");
  }

  createForms(): void {
    this.formGroup = this.fb.group({
        name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        society: ['', Validators.required],
        church: ['', Validators.required],
        sector: ['', Validators.required]
    });
}

  loadAssistant(){
    this.isLoading = true;
    this.registerListService.getDataUsers().then(resp => {
      this.dataSource = new MatTableDataSource(resp.data);
      setTimeout(() => {
        
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
      
      //console.log(this.dataSource);
      this.isLoading = false;
    }).catch(err => {
      this.isLoading = false;
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: 'No se pudo cargar la información, intente más tarde',
      });
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  showData(row){
    this.name = row.AsRg_Nombre;
    this.church = row.AsRg_Iglesia;
    this.sector = row.AsRg_Sector;
    this.keyAsst = row.AsRg_Key;
    this.cost = row.Monto
    this.originalCost = row.Monto;
    this.idUser = row.AsRg_IdAsst;
    this.tipoDsc = row.AsRg_TipoDscn;
    this.modalData.show();
  }

  checkAccess(){

    let check = (document.getElementById("crtsCheck") as HTMLInputElement);
  let checkSaf = (document.getElementById("safCheck") as HTMLInputElement);
  let typeDsc = 8;
  if(check.checked){
    typeDsc = 6;
  }
  if(checkSaf.checked){
    typeDsc = 9;
  }

    let data = {
      idUser: this.idUser,
      tipoDscn: typeDsc,
      monto: this.cost    }
      this.isLoading = true;
     this.modalData.hide();
    this.registerListService.registerDataUser(data).then(resp => {
      
      this.modalData.hide();
       Swal.fire({
         type: 'success',
         title: 'Acceso Registrado',
       });
       this.isLoading = false;
  
      this.loadAssistant();
    }).catch(err => {
      this.isLoading = false;
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: err,
      });
    });
    
     
    /*const check = (document.getElementById("localCheck") as HTMLInputElement);
     this.isLoading = true;
     this.modalData.hide();
     this.checkRegisterService.setAccess({key: this.keyAsst, monto: this.cost, sexo: gender, isLocal: check.checked}).then(resp => {
       this.modalData.hide();
       Swal.fire({
         type: 'success',
         title: 'Acceso Registrado',
       });
       this.isLoading = false;
     }).catch(err => {
       this.viewCam = false;
       this.isLoading = false;
       Swal.fire({
         type: 'error',
         title: 'Error',
         text: err,
       });
     });*/
     
   }
 

  showRegister(){
    this.modalregister.show();
  }

  sendData(){
    /*if(!this.formGroup.valid){
      Swal.fire({
        type: 'warning',
        title: 'Atención.',
        text: 'Favor de llenar todos los campos de manera correcta.'
      });
      return;
    }*/
    let data = {
      idUser: this.idUser,
      tipoDscn: this.tipoDsc,
      monto: this.cost    }

    this.registerListService.registerDataUser(data).then(resp => {
      
      this.isLoading = false;
      Swal.fire({
        type: 'success',
        title: 'Registro exitoso.',
      });

      this.modalregister.hide();
      this.formGroup.reset();
  
      this.loadAssistant();
    }).catch(err => {
      this.isLoading = false;
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: err,
      });
    });
  }


  dscCheck(ctrl){
    const checkCrts = (document.getElementById("crtsCheck") as HTMLInputElement);
    const checkSaf = (document.getElementById("safCheck") as HTMLInputElement); 
    this.cost = this.originalCost;

    if(ctrl === 1){
      if(checkCrts.checked){
        this.cost = 0;
        checkSaf.checked = false;
      }
    }

    if(ctrl === 2){
      if(checkSaf.checked){
        this.cost = 0;
        checkCrts.checked = false;
      }
    }

  }

  download(){
    let dataExcel = [];
    this.dataSource.filteredData.map(item => {
            
      let data = {
        Nombre: item.AsRg_Nombre,
        Correo: item.AsRg_Correo,
        Sociedad: item.AsRg_Grupo,
        Iglesia: item.AsRg_Iglesia,
        Presbiterio: this.getPresbiterio(item.AsRg_Sector),
      };
      dataExcel.push(data);
  });
    const fileName = `Registros_Velada`;
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataExcel);
    const workbook: XLSX.WorkBook = { Sheets: { 'Registros': worksheet }, SheetNames: ['Registros'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataFile: Blob = new Blob([excelBuffer], {
            type: EXCEL_TYPE
    });
      
    FileSaver.saveAs(dataFile, fileName + '_' + new Date().getFullYear()+ (new Date().getMonth()+1)+ (new Date().getDate())  + EXCEL_EXTENSION);
  }

  getPresbiterio(id){
    switch(+id){
      case 1:
        return 'Centro';
      case 2:
        return 'Estado de México';
      case 3:
        return 'Iglesia Central';
      case 4:
        return 'Metropolitano';
      case 5:
        return 'Misiones';
      case 6:
        return 'Suroriente';
      case 7:
        return 'Tlaxcala';
    }
  }

}
